  import React, { useState } from 'react';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import '../Css/Footer_css.css';
  import {  InstagramIcon } from './SocialIcons';
  import PrivacyPolicyModal from './PrivacyPolicyModal';

  const Footer = () => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const handlePrivacyPolicyClose = () => setShowPrivacyPolicy(false);
    const handlePrivacyPolicyShow = () => setShowPrivacyPolicy(true);

    return (
      <footer className="footer">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
              <h5>Pages</h5>
              <a href="/" className="text-white">Go to Home Page</a>
              <br></br>
              <a href="/order" className="text-white">Go to Order Page</a>  
            </div>
            <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
              <h5>Follow Us</h5>
              <div className="d-flex justify-content-center">
                {/* <a href="" target="_blank" rel="noopener noreferrer" className="me-3">
                  <FacebookIcon />
                </a> */}
                <a href="https://www.instagram.com/magnetale_/" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 text-center mb-4 mb-md-0">
              <h5>Contact Us</h5>
              <p>
                <strong>Phone Number:</strong> +961 81207946<br />
                <strong>Email Address:</strong> magnetale@hotmail.com
              </p>
            </div>
            <div className="col-md-3 col-sm-12 text-center">
              <a href="#" onClick={handlePrivacyPolicyShow} className="text-white">Privacy Policy</a>
              <p className="mb-0">© 2023 All rights reserved.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h5>Report a Problem</h5>
              <p className="your-paragraph-class">
                If you encounter any issues, please{' '}
                <a href="mailto:magnetale@hotmail.com">contact support</a>.
              </p>
            </div>
          </div>
        </div>
        <PrivacyPolicyModal show={showPrivacyPolicy} handleClose={handlePrivacyPolicyClose} />
      </footer>
    );
  };

  export default Footer;
