import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';

const NavLink = ({ to, isExternal, scrolled, isDrawer, children, ...props }) => {
  const commonClassName = `link-button ${scrolled ? 'scrolled' : ''} ${isDrawer ? 'drawer-link' : ''}`;

  if (isExternal) {
    return (
      <RouterLink to={to} className={commonClassName} {...props}>
        <Button color="inherit">{children}</Button>
      </RouterLink>
    );
  }

  return (
    <ScrollLink to={to} smooth={true} duration={500} className={commonClassName} {...props}>
      <Button color="inherit">{children}</Button>
    </ScrollLink>
  );
};

export default NavLink;
