import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles

import PromoSection from './PromoSection';
import Footer from './Footer';

const HeaderSection = React.lazy(() => import('./Header'));
const ProductIntroContainerSection = React.lazy(() => import('./ProductIntroContainer'));
const ProductContainerSection = React.lazy(() => import('./ProductContainer'));
const ReviewsSectionPage = React.lazy(() => import('./ReviewsSection'));

const HomePage = () => {
  return (
    <div className="home-container">
      <Suspense fallback={<div>Loading...</div>}>
        <div id="home-section">
          <HeaderSection />
        </div>
        <div id="about-section">
          <ProductIntroContainerSection />
          <PromoSection />
        </div>
        <div id="reviews-section">
          <ReviewsSectionPage />
        </div>
        <div id="gallery-section">
          <ProductContainerSection />
        </div>
        
        <Footer />
      </Suspense>
    </div>
  );
};

export default HomePage;
