import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import OrderForm from './Pages/JavaScript/OrderForm.js';
import HomePage from './Pages/JavaScript/HomePage.js';
import ChecOrder from './Pages/JavaScript/orders.js';
import SignIn from './Pages/JavaScript/SignIn.js';
import SignUp from './Pages/JavaScript/SignUp.js';
import { UserProvider } from './Pages/JavaScript/UserContext.js';
const theme = createTheme({
  palette: {
    text: {
      primary: '#593EA1',
      secondary: '#8F74A6',
    },
  },
});

function App() {
  return (
    <UserProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/order" element={<OrderForm />} />
            <Route path="/checkOrder" element={<ChecOrder />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
      </Router>
    </ThemeProvider>
    </UserProvider>
  );
}

export default App;
