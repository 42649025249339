// ReviewSection.js
import React, { useState, useEffect } from 'react';
import { db } from '../JavaScript/firebaseConfig';
import { collection, addDoc, getDocs, serverTimestamp, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { Box, Typography, Button, TextField, Rating, Alert } from '@mui/material';
import '../Css/ReviewsSection_css.css';

const ReviewSection = () => {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const [lastReviewDoc, setLastReviewDoc] = useState(null); // To track the last fetched review document

  const reviewsCollection = collection(db, 'reviews');

  useEffect(() => {
    fetchInitialReviews();
  }, []); // Fetch initial reviews on component mount

  const fetchInitialReviews = async () => {
    try {
      const queryRef = query(reviewsCollection, orderBy('timestamp', 'desc'), limit(3));
      const data = await getDocs(queryRef);
      const fetchedReviews = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setReviews(fetchedReviews);
      setLastReviewDoc(data.docs[data.docs.length - 1]); // Update last fetched review document
    } catch (error) {
      console.error('Error fetching initial reviews:', error);
    }
  };

  const fetchMoreReviews = async () => {
    try {
      if (!lastReviewDoc) return; // No more reviews to fetch
      const queryRef = query(reviewsCollection, orderBy('timestamp', 'desc'), startAfter(lastReviewDoc), limit(3));
      const data = await getDocs(queryRef);
      const fetchedReviews = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setReviews([...reviews, ...fetchedReviews]); // Append fetched reviews to existing reviews
      setLastReviewDoc(data.docs[data.docs.length - 1]); // Update last fetched review document
    } catch (error) {
      console.error('Error fetching more reviews:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (name.length < 2 || name.length > 20) {
      setError('Name must be between 2 and 20 characters');
      return;
    }

    if (comment.length < 10 || comment.length > 1000) {
      setError('Comment must be between 10 and 100 characters');
      return;
    }

    try {
      await addDoc(reviewsCollection, {
        name,
        comment,
        rating,
        timestamp: serverTimestamp(),
      });
      setName('');
      setComment('');
      setRating(0);

      // Fetch the updated list of reviews
      fetchInitialReviews();
    } catch (error) {
      setError('Error adding review: ' + error.message);
    }
  };

  return (
    <Box className="review-section">
      <Typography variant="h4" className="review-title">Customer Reviews</Typography>
      <Box className="review-form" component="form" onSubmit={handleSubmit}>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
        />
        <TextField
          label="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
          fullWidth
          multiline
          rows={4}
        />
        <Rating
          name="rating"
          value={rating}
          onChange={(e, newValue) => setRating(newValue)}
          required
        />
        <Button type="submit" variant="contained" style={{backgroundColor: '#593EA1',}}>
          Submit
        </Button>
      </Box>
      <Box className="reviews-list">
        {reviews.map((review) => (
          <Box key={review.id} className="review-item">
            <Typography variant="h6">{review.name}</Typography>
            <Rating value={review.rating} readOnly />
            <Typography>{review.comment}</Typography>
            <Typography variant="body2" color="textSecondary">
              {new Date(review.timestamp?.seconds * 1000).toLocaleDateString()}
            </Typography>
          </Box>
        ))}
        {lastReviewDoc && (
          <Button
            variant="outlined"
            color="primary"
            onClick={fetchMoreReviews}
          >
            Load More Reviews
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReviewSection;