// ProductContainer.js
import React from 'react';
import '../Css/ProductContainer_css.css';
import picture1 from '../Imgs/gallery1.jpg';
import picture2 from '../Imgs/gallery2.jpeg';
import picture3 from '../Imgs/gallery3.jpg';
import picture4 from '../Imgs/magnet6.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography} from '@mui/material';

const productImages = [
  picture1,
  picture2,
  picture3,
  picture4,
];

const ProductContainer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 slides at a time for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides at a time for medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 slides at a time for small screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 slide at a time for extra small screens
        },
      },
    ],
  };

  return (
    <div className="product-container">
      <section className="gallery">
        <Typography variant="h4" className="gallery-title">Gallery</Typography>
        <Slider {...settings} className="gallery-container">
          {productImages.map((image, index) => (
            <div key={index} className="gallery-item">
              <img src={image} alt={`Product ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </section>
    </div>
  );
};

export default ProductContainer;