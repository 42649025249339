import React, { useEffect, useState  } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, updateDoc, doc, onSnapshot } from 'firebase/firestore';

import '../Css/Orders.css'; // Import your CSS file
import Footer from './Footer';
import Header from './Header';
const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersCollection = collection(db, 'orders');
        const ordersSnapshot = await getDocs(ordersCollection);
        const initialOrdersList = ordersSnapshot.docs
          .filter(doc => doc.id !== 'WzOIiERJRSQCowq4tJyq') // Exclude specific document ID
          .map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(initialOrdersList);

        // Listen for real-time updates
        const unsubscribe = onSnapshot(ordersCollection, (snapshot) => {
          const updatedOrdersList = snapshot.docs
            .filter(doc => doc.id !== 'WzOIiERJRSQCowq4tJyq') // Exclude specific document ID
            .map(doc => ({ id: doc.id, ...doc.data() }));
          setOrders(updatedOrdersList);
        });

        return unsubscribe;
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'image.jpg'); // Set a default name or extract it from URL
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl); // Clean up the object URL
    } catch (error) {
      console.error('Failed to fetch', error);
    }
  };

  const handleCheckboxChange = async (orderId, field, value) => {
    try {
      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, {
        [field]: value
      });

      // Update local state immediately
      setOrders(prevOrders => prevOrders.map(order => {
        if (order.id === orderId) {
          return { ...order, [field]: value };
        }
        return order;
      }));
    } catch (error) {
      console.error(`Error updating ${field} for order ${orderId}:`, error);
    }
  };

  return (
    <div>
    <div className="orders-container">
      <h1>Orders</h1>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Document ID</th>
            <th>Address</th>
            <th>Email</th>
            <th>Name</th>
            <th>Package</th>
            <th>Phone</th>
            <th>Price</th>
            <th>Images</th>
            <th>Client Feedback</th>
            <th>Order Delivered</th>
            <th>Order Done</th>

          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td data-label="Document ID">{order.id}</td>
              <td data-label="Address">{order.Address}</td>
              <td data-label="Email">{order.Email}</td>
              <td data-label="Name">{order.Name}</td>
              <td data-label="Package">{order.Package}</td>
              <td data-label="Phone">{order.Phone}</td>
              <td data-label="Price">{order.Price}</td>
              <td data-label="Images">{order.imageUrls.map((url, index) => (
                  <button onClick={() => downloadImage(url)} key={index}>
                    Download Image {index + 1}
                  </button>
                ))}</td>
              <td data-label="Client Feedback">
                <input
                  type="text"
                  value={order.client_feedback || ''}
                  onChange={(e) => handleCheckboxChange(order.id, 'client_feedback', e.target.value)}
                />
              </td>
              <td data-label="Order Delivered">
                <input
                  type="checkbox"
                  checked={order.order_delivered}
                  onChange={(e) => handleCheckboxChange(order.id, 'order_delivered', e.target.checked)}
                />
              </td>
              <td data-label="Order Done">
                <input
                  type="checkbox"
                  checked={order.order_done}
                  onChange={(e) => handleCheckboxChange(order.id, 'order_done', e.target.checked)}
                />
              </td>

              </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default Orders;
