// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBbc2TFR5gl_6sQDEhEFsumGoOuZqk-P2c",
  authDomain: "magnetale-b28da.firebaseapp.com",
  projectId: "magnetale-b28da",
  storageBucket: "magnetale-b28da.appspot.com",
  messagingSenderId: "377212513107",
  appId: "1:377212513107:web:8f4057f68865f30de90fa6",
  measurementId: "G-MJKY5MXDQ8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, storage };