import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, query, collection, where, doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import PrivacyPolicyModal from './PrivacyPolicyModal';
// Import the image
import sideImage from '../Imgs/magnet4.jpg';

function Copyright({ handlePrivacyPolicyShow, ...otherProps }) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...otherProps}>
       © {new Date().getFullYear()} All rights reserved.
       <br />
      <Link href="#" onClick={handlePrivacyPolicyShow}>
        Privacy Policy
      </Link>
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const handlePrivacyPolicyClose = () => setShowPrivacyPolicy(false);
  const handlePrivacyPolicyShow = () => setShowPrivacyPolicy(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError('Please provide both email and password.');
      return;
    }

    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('Signed in:', user);

      // Fetch user data from Firestore using UID
      // Fetch the user document by email, adjust path accordingly
      const userQuery = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        // Get the document ID from the query result
        const userDocId = querySnapshot.docs[0].id;
        const userDoc = doc(db, 'users', userDocId);
        const userSnap = await getDoc(userDoc);
  
        if (userSnap.exists()) {
          const data = userSnap.data();
          setUserData(data);
  
          // Check if the user is an admin
          if (data.role === 'admin') {
            navigate('/checkorder'); // Redirect to /checkorder if admin
          } else {
            navigate('/'); // Redirect to /home if not admin
          }
        } else {
          setError('No such user found in Firestore.');
          console.log('User document not found in Firestore at path:', `users/${userDocId}`);
        }
      } else {
        setError('No user found with this email.');
        console.log('No user document found for email:', email);
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      setError('Error signing in. Please check your email and password.');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${sideImage})`,
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update state on change
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update state on change
              />
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Grid container>
              <Grid item>
                  <Link href="/" variant="body2">
                    {"Go Back To Home Page"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} handlePrivacyPolicyShow={handlePrivacyPolicyShow} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PrivacyPolicyModal show={showPrivacyPolicy} handleClose={handlePrivacyPolicyClose} />
    </ThemeProvider>
  );
}
